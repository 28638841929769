.react-contextmenu {
    background-color: white;
    border: 1px solid $border-color;
    border-radius: 3px;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
    z-index: 1030;
    padding: 8px 0;
    font-size: 14px;
}

.react-contextmenu-item {
    @include ctx-menu-item(action-icon);
    color: $text-primary-color;
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

.ctx-menu-item--detail {
    @include ctx-menu-item--detail;
    width: 250px;
}
